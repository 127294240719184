import md5 from 'js-md5'
export var ContractAwardSearchList = [
  { labe: '合同编号', code: 'number', type: 'input' },
  {
    labe: '合同类型',
    code: 'contractType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '订单', value: '1' },
      { label: '框架合同', value: '2' },
      { label: '订购合同', value: '3' },
      { label: '非标合同', value: '4' }
    ]
  },
  {
    labe: '我方主体',
    code: 'mySubject',
    type: 'select',
    option: [
      { label: '可控', value: 'KK' },
      { label: '曙光', value: 'SG' },
      { label: '宁畅', value: 'NC' },
      { label: '腾龙', value: 'TL' },
      { label: '数创', value: 'SC' }
    ]
  },
  // { labe: '我方主体', code: 'mySubject', type: 'input' },
  { labe: '供应商主体', code: 'subject', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var ContractAwardSearchData = {
  number: '',
  contractType: '',
  mySubject: '',
  subject: '',
  approvalStatus: ''
}

export var ContractAwardTableField = [
  { label: '合同编号', code: 'number', type: 'input' },
  {
    label: '合同类型',
    code: 'contractType',
    type: 'function',
    width: '',
    handle: function (index, row) {
      return row.contractType === '1' ? '订单' : row.contractType === '2' ? '框架合同' : row.contractType === '3' ? '订购合同'
        : row.contractType === '4' ? '非标合同' : row.contractType
    }
  },
  { label: '合同名称', code: 'name', type: 'input', width: '' },
  {
    label: '我方主体',
    code: 'mySubject',
    type: 'function',
    width: '100',
    handle: function (index, row) {
      if (row.mySubject === 'KK') {
        return '可控'
      }
      if (row.mySubject === 'SG') {
        return '曙光'
      }
      if (row.mySubject === 'NC') {
        return '宁畅'
      }
      if (row.mySubject === 'TL') {
        return '腾龙'
      }
      if (row.mySubject === 'SC') {
        return '数创'
      }
    }
  },
  // { label: '我方主体', code: 'mySubject', type: 'input', width: '' },
  { label: '供应商主体', code: 'subject', type: 'input' },
  {
    label: '合同介质',
    code: 'medium',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.medium === 0 ? '纸质合同' : '电子合同'
    }
  },
  {
    label: '供应商是否签章',
    code: 'supplierIsSignature',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.supplierIsSignature === 0 ? '是' : '否'
    }
  },
  {
    label: '审批状态',
    code: 'approvalStatus',
    type: 'function',
    width: '',
    handle: function (index, data) {
      const color = md5(data.approvalStatus + '').substring(0, 6)
      const label = data.approvalStatus === 1 ? '草稿' : data.approvalStatus === 2 ? '审批中' : data.approvalStatus === 3 ? '审批结束' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var ContractAwardTableData = []
export var ContractAwardNavData = [{
  label: '我的发起',
  name: 'myapp',
  id: 1
}, {
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '我已审批',
  name: 'myapprd',
  id: 3
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
