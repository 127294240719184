import md5 from 'js-md5'
export var PaymentApprovalSearchList = [
  { labe: '付款单号', code: 'payCode', type: 'input' },
  { labe: '开票方', code: 'drawer', type: 'input' },
  {
    labe: '付款类型',
    code: 'payWay',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '银承汇票', value: '01' },
      { label: '银行存款', value: '02' }
    ]
  },
  {
    labe: '申请类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '应付', value: '1' },
      { label: '预付', value: '2' }
    ]
  },
  { labe: '创建日期', code: 'createDate', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var PaymentApprovalSearchData = {
  payCode: '',
  drawer: '',
  payWay: '',
  type: '',
  createDate: '',
  approvalStatus: ''
}
export var PaymentApprovalTableField = [
  { label: '付款单号', code: 'payCode', type: 'input', width: '138px' },
  {
    label: '申请类型',
    code: 'type',
    type: 'function',
    handle: function (index, row) {
      return row.type === 1 ? '应付' : row.type === 2 ? '预付' : row.type
    }
  },
  { label: '当前处理人', code: 'approval', type: 'input', width: '130px' },
  {
    label: '审批状态',
    code: 'approvalStatus',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.approvalStatus + '').substring(0, 6)
      const label = row.approvalStatus === 1 ? '草稿' : row.approvalStatus === 2 ? '审批中' : row.approvalStatus === 3 ? '审批结束' : row.approvalStatus
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  { label: '申请总额', code: 'totalMoney', type: 'amount', align: 'right', width: '' },
  /*   { label: '票据金额', code: 'billMoney', type: 'input', width: '' }, */
  { label: '币种', code: 'currency', type: 'input', width: '' },
  {
    label: '付款类型',
    code: 'payWay',
    type: 'function',
    width: '',
    handle (index, data) {
      return data.payWay === '01' ? '银承汇票' : data.payWay === '02' ? '银行存款' : data.payWay
    }
  },
  { label: '开票方', code: 'drawerName', type: 'input', width: '' },
  { label: '开票方编码', code: 'drawerCode', type: 'input', width: '' },
  { label: '备注', code: 'bz', type: 'input', width: '' },
  /*   { label: '银行账号', code: 'bankAccount', type: 'input', width: '' },
  { label: '付款账号', code: 'payAccount', type: 'input', width: '' }, */
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var PaymentApprovalTableData = []
export var PaymentApprovalNavData = [{
  label: '我的发起',
  name: 'myapp',
  id: 1
}, {
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '我已审批',
  name: 'myapprd',
  id: 3
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
