<template>
  <div>
    <list-search @onSearch="onSearch" @clearSearch="onSearch" :childData="listSearchData"></list-search>
    <table-tab :key="$route.query.ViewId" :TabData="navData" @tabSwitch="onSwitch" :defaultData="defaultSwitch"></table-tab>
    <global-table :key="$route.query.ViewId" :tableField="tableField" :pageNum="searchData.pageNum" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"></global-table>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { config } from './js/setting.js'
export default {
  name: 'ApprovalList',
  components: Component.components,
  data: function () {
    let pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      listSearchData: {},
      tableField: {},
      tableData: [],
      searchData: { pageNum: 1, pageSize },
      pageSize: pageSize,
      defaultSwitch: '',
      tabSelectData: '',
      paginationData: { total: 1, pageSize },
      navData: [],
      requestUri: ''
    }
  },
  created () {
    var id = this.$route.query.ViewId
    if (id) {
      this.init(id)
    }
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.ViewId
        if (id) {
          this.init(id)
        }
      }
    }
  },
  methods: {
    init (id) {
      this.listSearchData = config[id].listSearchData
      this.tableField = config[id].tableField
      this.searchData = { pageNum: 1, pageSize: this.pageSize }
      this.defaultSwitch = config[id].defaultSwitch
      this.paginationData = { total: 1, pageSize: this.pageSize }
      this.navData = config[id].navData
      this.requestUri = config[id].requestUri
      this.defaultSwitchTab(config[id].defaultSwitch)
      // this.requestData(this.searchData)
    },
    onSearch (data) {
      this.searchData = { pageNum: 1, pageSize: this.pageSize }
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.searchData = { ...this.searchData, ...data }
      this.searchData.pageNum = 1
      this.searchData.pageSize = this.pageSize
      this.requestData(this.searchData)
    },
    pageChange (data) {
      this.searchData.pageNum = data
      this.requestData(this.searchData)
    },
    tableAction (index, data) {
      var id = this.$route.query.ViewId
      config[id].action.call(this, index, data)
    },
    defaultSwitchTab (data) {
      const loginUser = localStorage.getItem('userName')
      const loginId = localStorage.getItem('userId')
      const searchdata = { pageNum: 1, pageSize: this.pageSize }
      if (data === 'myapp') {
        this.tabSelectData = '我的发起'
        searchdata.createUserId = loginId
        this.searchData = searchdata
      }
      if (data === 'myappr') {
        this.tabSelectData = '待我审批'
        searchdata.approval = loginUser
        searchdata.approvalStatus = 2
        this.searchData = searchdata
      }
      if (data === 'myapprd') {
        this.tabSelectData = '我已审批'
        searchdata.approvalComplete = loginUser
        this.searchData = searchdata
      }
      if (data === 'all') {
        this.tabSelectData = '全部'
        this.searchData = searchdata
      }
      this.requestData(searchdata)
    },
    onSwitch (tabData) {
      var data = tabData.props.label
      this.tabSelectData = data
      const loginUser = localStorage.getItem('userName')
      const loginId = localStorage.getItem('userId')
      const searchdata = { pageNum: 1, pageSize: this.pageSize }
      if (data === '我的发起') {
        searchdata.createUserId = loginId
        this.searchData = searchdata
      }
      if (data === '待我审批') {
        searchdata.approval = loginUser
        searchdata.approvalStatus = 2
        this.searchData = searchdata
      }
      if (data === '我已审批') {
        searchdata.approvalComplete = loginUser
        this.searchData = searchdata
      }
      if (data === '全部') {
        this.searchData = searchdata
      }
      this.requestData(searchdata)
    },
    requestData (data) {
      request(this.requestUri, 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.table_tab{
  margin-top:20px ;
  margin-left:20px ;
}
</style>
