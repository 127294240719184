export var InquiryResultSearchList = [
  { labe: '询价编号', code: 'inquiryCode', type: 'input' },
  { labe: '询价描述', code: 'inquiryDesc', type: 'input' },
  { labe: '公司名称', code: 'companyName', type: 'input' },
  { labe: '采购员', code: 'createUserName', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var InquiryResultSearchData = {
  inquiryCode: '',
  inquiryDesc: '',
  companyName: '',
  createUserName: '',
  approvalStatus: ''
}
export var InquiryResultTableField = [
  { label: '询价编号', code: 'inquiryCode', type: 'input' },
  { label: '询价描述', code: 'inquiryDesc', type: 'input' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '采购员', code: 'createUserName', type: 'input', width: '' },
  { label: '提交日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var InquiryResultTableData = []
export var InquiryResultNavData = [{
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
