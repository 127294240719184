import md5 from 'js-md5'
export var LifecycleSearchList = [
  { labe: '供应商名称', code: 'providerInfo', type: 'input' },
  {
    labe: '物料品类',
    code: 'materialType',
    type: 'select',
    option: [
      { label: '主板', value: '主板' },
      { label: 'CPU', value: 'CPU' },
      { label: '硬盘', value: '硬盘' },
      { label: '内存', value: '内存' },
      { label: '板卡', value: '板卡' },
      { label: '电源', value: '电源' },
      { label: '风扇', value: '风扇' },
      { label: '散热器', value: '散热器' },
      { label: '线缆', value: '线缆' },
      { label: '交换机', value: '交换机' },
      { label: '磁盘阵列', value: '磁盘阵列' },
      { label: 'OEM安全产品', value: 'OEM安全产品' },
      { label: '商品', value: '商品' },
      { label: '包材', value: '包材' },
      { label: '结构件', value: '结构件' },
      { label: '光模块', value: '光模块' },
      { label: '软件', value: '软件' },
      { label: '固定资产', value: '固定资产' },
      { label: '外设', value: '外设' },
      { label: '云计算集成外包', value: '云计算集成外包' },
      { label: '外协', value: '外协' },
      { label: '元器件', value: '元器件' },
      { label: '其它', value: '其它' }
    ]
  },
  {
    labe: '调整建议',
    code: 'adjustAdvice',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '一次升级到一般', value: '1' },
      { label: '一次升级到合格', value: '2' },
      { label: '一般升级到合格', value: '3' },
      { label: '一般降级到一次', value: '4' },
      { label: '合格降级到一般', value: '5' },
      { label: '合格降级到淘汰', value: '6' },
      { label: '一次降级到淘汰', value: '7' },
      { label: '一般降级到淘汰', value: '8' }
    ]
  },
  {
    labe: '审批前级别',
    code: 'adjustBeforeLevel',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '一次', value: '1' },
      { label: '一般', value: '2' },
      { label: '合格', value: '3' },
      { label: '淘汰', value: '4' }
    ]
  },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '待确认', value: '1' },
      { label: '审批中', value: '2' },
      { label: '驳回', value: '3' },
      { label: '已调整', value: '4' }
    ]
  }
]
export var LifecycleSearchData = {
  providerInfo: '',
  materialType: '',
  adjustAdvice: '',
  adjustBeforeLevel: '',
  approvalStatus: ''
}
export var LifecycleTableField = [
  { label: '供应商编码', code: 'providerCode', type: 'input' },
  { label: '供应商名称', code: 'providerName', type: 'input' },
  { label: '物料品类', code: 'materialType', type: 'input', width: '' },
  {
    label: '经营范围',
    code: 'businessScope',
    type: 'function',
    width: '',
    handle: function (index, row) {
      if (row.businessScope === 'KK') {
        return '可控'
      }
      if (row.businessScope === 'SG') {
        return '曙光'
      }
      if (row.businessScope === 'NC') {
        return '宁畅'
      }
      if (row.businessScope === 'TL') {
        return '腾龙'
      }
    }
  },
  {
    label: '级别',
    code: 'adjustBeforeLevel',
    type: 'function',
    width: '',
    handle: function (index, row) {
      return row.adjustBeforeLevel === '1' ? '一次' : row.adjustBeforeLevel === '2' ? '一般'
        : row.adjustBeforeLevel === '3' ? '合格' : row.adjustBeforeLevel === '4' ? '淘汰' : row.adjustBeforeLevel
    }
  },
  {
    label: '调整建议',
    code: 'adjustAdvice',
    type: 'function',
    width: '',
    handle: function (index, row) {
      return row.adjustAdvice === '1' ? '一次升级到一般' : row.adjustAdvice === '2' ? '一次升级到合格' : row.adjustAdvice === '3' ? '一般升级到合格'
        : row.adjustAdvice === '4' ? '一般降级到一次' : row.adjustAdvice === '5' ? '合格降级到一般' : row.adjustAdvice === '6' ? '合格降级到淘汰'
          : row.adjustAdvice === '7' ? '一次降级到淘汰' : row.adjustAdvice === '8' ? '一般降级到淘汰' : row.adjustAdvice
    }
  },
  { label: '申请人', code: 'applyUserName', type: 'input', width: '' },
  { label: '当前处理人', code: 'approval', type: 'input', width: '' },
  {
    label: '审批状态',
    code: 'approvalStatus',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.approvalStatus + '').substring(0, 6)
      const label = row.approvalStatus === '1' ? '待确认' : row.approvalStatus === '2' ? '审批中' : row.approvalStatus === '3' ? '驳回' : '已调整'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var LifecycleTableData = [
  {
    approvalStatus: '2',
    providerCode: ''
  }
]
export var LifecycleNavData = [{
  label: '我的发起',
  name: 'myapp',
  id: 1
}, {
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '我已审批',
  name: 'myapprd',
  id: 3
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
