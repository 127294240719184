export var TenderAwardedSearchList = [
  { labe: '招标编号', code: 'bidCode', type: 'input' },
  { labe: '项目名称', code: 'itemName', type: 'input' },
  { labe: '公司名称', code: 'companyName', type: 'input' },
  { labe: '采购员', code: 'createUserName', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var TenderAwardedSearchData = {
  bidCode: '',
  itemName: '',
  companyName: '',
  createUserName: '',
  approvalStatus: ''
}
export var TenderAwardedTableField = [
  { label: '招标编号', code: 'bidCode', type: 'input' },
  { label: '项目名称', code: 'itemName', type: 'input' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '开标日期', code: 'bidStartDate', type: 'input', width: '' },
  { label: '采购员', code: 'createUserName', type: 'input', width: '' },
  { label: '提交日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var TenderAwardedTableData = []
export var TenderAwardedNavData = [{
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
