import md5 from 'js-md5'
export var MaterialApprovalSearchList = [
  { labe: '供应商名称', code: 'supplierName', type: 'input' },
  { labe: '物料编码', code: 'materialCode', type: 'input' },
  { labe: '物料描述', code: 'materialDesc', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' },
      { label: '驳回', value: '4' }
    ]
  }
]
export var MaterialApprovalSearchData = {
  supplierName: '',
  materialCode: '',
  materialDesc: '',
  approvalStatus: ''
}
export var MaterialApprovalTableField = [
  { label: '供应商名称', code: 'supplierName', type: 'input' },
  { label: '物料编号', code: 'materialCode', type: 'input' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '' },
  { label: '当前处理人', code: 'approval', type: 'input', width: '' },
  {
    label: '审批状态',
    code: 'approvalStatus',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.approvalStatus + '').substring(0, 6)
      const label = row.approvalStatus === 1 ? '草稿' : row.approvalStatus === 2 ? '审批中' : row.approvalStatus === 3 ? '审批结束' : row.approvalStatus === 4 ? '驳回' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
      // return row.approvalStatus === 1 ? '草稿' : row.approvalStatus === 2 ? '审批中' : row.approvalStatus === 3 ? '审批结束' : row.approvalStatus === 4 ? '驳回' : ''
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var MaterialApprovalTableData = []
export var MaterialApprovalNavData = [{
  label: '我的发起',
  name: 'myapp',
  id: 1
}, {
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '我已审批',
  name: 'myapprd',
  id: 3
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
