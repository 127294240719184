import md5 from 'js-md5'
export var BarCodeIssueSearchList = [
  { labe: '供应商名称', code: 'supplier', type: 'input' },
  { labe: '物料编码', code: 'itemNumber', type: 'input' },
  { labe: '物料描述', code: 'itemMessage', type: 'input' },
  {
    labe: '审批状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待审批', value: '0' },
      { label: '审批通过', value: '1' },
      { label: '驳回', value: '2' }
    ]
  }
]
export var BarCodeIssueSearchData = {
  supplier: '',
  itemNumber: '',
  itemMessage: '',
  status: ''
}
export var BarCodeIssueTableField = [
  { label: '供应商编码', code: 'supplierCode', type: 'input' },
  { label: '供应商名称', code: 'supplier', type: 'input' },
  { label: '物料编号', code: 'itemNumber', type: 'input' },
  { label: '物料描述', code: 'itemMessage', type: 'input', width: '' },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '' },
  { label: '当前处理人', code: 'approval', type: 'input', width: '' },
  {
    label: '审批状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.status + '').substring(0, 6)
      const label = row.status === 0 ? '待审批' : row.status === 1 ? '审批通过' : '驳回'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var BarCodeIssueTableData = []
export var BarCodeIssueNavData = [{
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
