import { SupplierSearchList, SupplierSearchData, SupplierTableField, SupplierTableData, SupplierNavData } from './SupplierRegistration.js'
import { LifecycleSearchList, LifecycleSearchData, LifecycleTableField, LifecycleTableData, LifecycleNavData } from './SupplierLifecycle.js'
import { MaterialApprovalSearchList, MaterialApprovalSearchData, MaterialApprovalTableField, MaterialApprovalTableData, MaterialApprovalNavData } from './MaterialApproval.js'
import { BarCodeIssueSearchList, BarCodeIssueSearchData, BarCodeIssueTableField, BarCodeIssueTableData, BarCodeIssueNavData } from './BarCodeIssue.js'
import { TenderAwardedSearchList, TenderAwardedSearchData, TenderAwardedTableField, TenderAwardedTableData, TenderAwardedNavData } from './TenderAwarded.js'
import { InquiryResultSearchList, InquiryResultSearchData, InquiryResultTableField, InquiryResultTableData, InquiryResultNavData } from './InquiryResult.js'
import { ContractAwardSearchList, ContractAwardSearchData, ContractAwardTableField, ContractAwardTableData, ContractAwardNavData } from './ContractAward.js'
import { CheckDifferencesSearchList, CheckDifferencesSearchData, CheckDifferencesTableField, CheckDifferencesTableData, CheckDifferencesNavData } from './CheckDifferences.js'
import { StockCountSearchList, StockCountSearchData, StockCountTableField, StockCountTableData, StockCountNavData } from './StockCount.js'
import { PaymentApprovalSearchList, PaymentApprovalSearchData, PaymentApprovalTableField, PaymentApprovalTableData, PaymentApprovalNavData } from './PaymentApproval.js'
export var config = {
  SupplierRegistration: {
    listSearchData: { searchList: SupplierSearchList, searchData: SupplierSearchData },
    defaultSwitch: 'myappr',
    tableField: SupplierTableField,
    tableData: SupplierTableData,
    navData: SupplierNavData,
    requestUri: '/api/supplier/basicData/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'SupplierRegistration' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'SupplierRegistration' })
    }
  },
  SupplierLifecycle: {
    listSearchData: { searchList: LifecycleSearchList, searchData: LifecycleSearchData },
    defaultSwitch: 'myappr',
    tableField: LifecycleTableField,
    tableData: LifecycleTableData,
    navData: LifecycleNavData,
    requestUri: '/api/supplier/supplierLevel/getApprovalDataList',
    // requestUri: '/api/supplier/basicData/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'SupLifecycleApproval' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'SupLifecycleApproval' })
    }
  },
  MaterialApproval: {
    listSearchData: { searchList: MaterialApprovalSearchList, searchData: MaterialApprovalSearchData },
    defaultSwitch: 'myappr',
    tableField: MaterialApprovalTableField,
    tableData: MaterialApprovalTableData,
    navData: MaterialApprovalNavData,
    requestUri: '/api/supplier/material/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'MaterialApproval' }, query: { id: data.id, state: data.approvalStatus } })
      this.$emit('OpenView', { openView: 'MaterialApproval' })
    }
  },
  BarCodeIssue: {
    listSearchData: { searchList: BarCodeIssueSearchList, searchData: BarCodeIssueSearchData },
    defaultSwitch: 'myappr',
    tableField: BarCodeIssueTableField,
    tableData: BarCodeIssueTableData,
    navData: BarCodeIssueNavData,
    requestUri: '/api/barcode/barcode/approvalQueryAll',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'BarCodeApproval' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'BarCodeApproval' })
    }
  },
  TenderAwarded: {
    listSearchData: { searchList: TenderAwardedSearchList, searchData: TenderAwardedSearchData },
    defaultSwitch: 'myappr',
    tableField: TenderAwardedTableField,
    tableData: TenderAwardedTableData,
    navData: TenderAwardedNavData,
    requestUri: '/api/bid/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'BidFormAll' }, query: { id: data.bidId } })
      this.$emit('OpenView', { openView: 'BidFormAll' })
    }
  },
  InquiryResult: {
    listSearchData: { searchList: InquiryResultSearchList, searchData: InquiryResultSearchData },
    defaultSwitch: 'myappr',
    tableField: InquiryResultTableField,
    tableData: InquiryResultTableData,
    navData: InquiryResultNavData,
    requestUri: '/api/inquiry/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'IssueDetails' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'IssueDetails' })
    }
  },
  ContractAward: {
    listSearchData: { searchList: ContractAwardSearchList, searchData: ContractAwardSearchData },
    defaultSwitch: 'myappr',
    tableField: ContractAwardTableField,
    tableData: ContractAwardTableData,
    navData: ContractAwardNavData,
    requestUri: '/api/pact/contract/approvalQueryAll',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'DraftaContract' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'DraftaContract' })
    }
  },
  CheckDifferences: {
    listSearchData: { searchList: CheckDifferencesSearchList, searchData: CheckDifferencesSearchData },
    defaultSwitch: 'myappr',
    tableField: CheckDifferencesTableField,
    tableData: CheckDifferencesTableData,
    navData: CheckDifferencesNavData,
    requestUri: '/api/reconciliation/reconciliationInfo/approvalQueryAll',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'StatementSeeDetail' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'StatementSeeDetail' })
    }
  },
  StockCount: {
    listSearchData: { searchList: StockCountSearchList, searchData: StockCountSearchData },
    defaultSwitch: 'myappr',
    tableField: StockCountTableField,
    tableData: StockCountTableData,
    navData: StockCountNavData,
    requestUri: '/api/supplier/basicData/list',
    action: function (index, data) {
      this.$router.push({ params: { viewName: 'SupplierRegistration' }, query: { id: data.id } })
      this.$emit('OpenView', { openView: 'SupplierRegistration' })
    }
  },
  PaymentApproval: {
    listSearchData: { searchList: PaymentApprovalSearchList, searchData: PaymentApprovalSearchData },
    defaultSwitch: 'myappr',
    tableField: PaymentApprovalTableField,
    tableData: PaymentApprovalTableData,
    navData: PaymentApprovalNavData,
    requestUri: '/api/reconciliation/pay/paymentOrderList',
    action: function (index, data) {
      if (data.type === 1) {
        this.$router.push({ params: { viewName: 'PaymentFindDetail' }, query: { id: data.id } })
        this.$emit('OpenView', { openView: 'PaymentFindDetail' })
      } else {
        // eslint-disable-next-line eqeqeq
        if (data.approvalStatus == 1 && this.tabSelectData && this.tabSelectData === '我的发起') {
          this.$router.push({ params: { viewName: 'CreateaplanPayreq' }, query: { id: data.id } })
          this.$emit('OpenView', { openView: 'CreateaplanPayreq' })
        } else {
          this.$router.push({ params: { viewName: 'AdvancePaymentFindDetail' }, query: { id: data.id } })
          this.$emit('OpenView', { openView: 'AdvancePaymentFindDetail' })
        }
      }
    }
  }
}
