import md5 from 'js-md5'
export var SupplierSearchList = [
  { labe: '供应商名称', code: 'name', type: 'input' },
  {
    labe: '供应物料',
    code: 'supplyMaterialType',
    type: 'select',
    option: [
      { label: '全部', value: 'All' }
    ]
  },
  {
    labe: '供应商级别',
    code: 'type',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '合格', value: '3' },
      { label: '一般', value: '2' },
      { label: '一次', value: '1' }
    ]
  },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var SupplierSearchData = {
  name: '',
  supplyMaterialType: '',
  type: '',
  approvalStatus: ''
}
export var SupplierTableField = [
  { label: '供应商名称', code: 'name', type: 'input', width: '250%' },
  { label: '供应物料', code: 'supplyMaterialType', type: 'input', width: '' },
  {
    label: '经营范围',
    code: 'businessScope',
    type: 'function',
    width: '',
    handle: function (index, row) {
      let rd = ''
      for (const d of row.businessScopeList) {
        if (d === 'KK') {
          rd = rd + '可控;'
        }
        if (d === 'SG') {
          rd = rd + '曙光;'
        }
        if (d === 'NC') {
          rd = rd + '宁畅;'
        }
        if (d === 'TL') {
          rd = rd + '腾龙;'
        }
        if (d === 'SC') {
          rd = rd + '数创;'
        }
      }
      return rd.split(';').filter((s) => { return s && s.trim() }).join(';')
    }
  },
  {
    label: '级别',
    code: 'type',
    type: 'function',
    width: '',
    handle: function (index, row) {
      return row.type === 1 ? '一次' : row.type === 2 ? '一般' : row.type === 3 ? '合格' : row.type
    }
  },
  { label: '当前处理人', code: 'approval', type: 'input', width: '' },
  {
    label: '审批状态',
    code: 'approvalStatus',
    type: 'function',
    width: '',
    handle: function (index, row) {
      const color = md5(row.approvalStatus + '').substring(0, 6)
      const label = row.approvalStatus === 1 ? '草稿' : row.approvalStatus === 2 ? '审批中' : row.approvalStatus === 3 ? '审批结束' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var SupplierTableData = []
export var SupplierNavData = [{
  label: '我的发起',
  name: 'myapp',
  id: 1
}, {
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '我已审批',
  name: 'myapprd',
  id: 3
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
