export var StockCountSearchList = [
  { labe: '对账单编号', code: 'name', type: 'input' },
  { labe: '公司名称', code: 'supplyMaterialType', type: 'input' },
  { labe: '供应商', code: 'supplyMaterialType', type: 'input' },
  {
    labe: '审批状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '草稿', value: '1' },
      { label: '审批中', value: '2' },
      { label: '审批结束', value: '3' }
    ]
  }
]
export var StockCountSearchData = {
  name: '',
  supplyMaterialType: '',
  type: '',
  approvalStatus: ''
}
export var StockCountTableField = [
  { label: '盘点单编号', code: 'code', type: 'input' },
  { label: '供应商名称', code: 'approval', type: 'input', width: '' },
  { label: '库位', code: 'approval', type: 'input', width: '' },
  { label: '盘点时间', code: 'approval', type: 'input', width: '' },
  { label: '操作人', code: 'approval', type: 'input', width: '' },
  { label: '备注', code: 'approval', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [{
      actionLabel: '查看',
      id: '10'
    }]
  }
]
export var StockCountTableData = []
export var StockCountNavData = [{
  label: '待我审批',
  name: 'myappr',
  id: 2
}, {
  label: '全部',
  name: 'all',
  id: 4
}]
